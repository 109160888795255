
//  file-level parsers, delegate to data parser for specific MIME types.

import { useContext } from 'react';
import { ParseAsyncContext } from './context';
import { ModelParser, ParseContext, Resource, ResourceParseOutcome } from './model';
import { WorkbookParser } from './workbook';


// reads the content and of a file and forwards it to a parser upstream.
export const useFileParser = (type: 'binary' | 'text') => (resource: Resource) => ({

  with: <T>(parser: (data: any) => Promise<ResourceParseOutcome<T>>) => new Promise<ResourceParseOutcome<T>>((resolve, reject) => {

    const reader = new FileReader();

    reader.onload = () => {

      if (reader.result)
        try {

          const parsed = parser(reader.result)

          resolve(parsed)

        }
        catch (e) {

          console.error(e)

          reject(`parsing error: ${e}`)
        }

      else
        reject(`file is empty.`)
    }

    reader.onerror = e => reject(`reading error: ${e}`)

    if (type === 'text')
      reader.readAsText(resource.file)
    else
      reader.readAsBinaryString(resource.file)
  })

})

// "extends" file parser to process the contents of a file as an array of JSON objects.
export const useJsonParser = <T, S>() => {

  const parse = useFileParser('text')

  return (resource: Resource) => ({

    with: (modelparser: ModelParser<T,S>, ctx: ParseContext<S>) => parse(resource).with(async data => {

      const read = JSON.parse(data)

      const parsed: T[] = Array.isArray(read) ? read : [read]

      return modelparser(parsed, ctx)
    })

  })
}



export type XlsXParseContext = Partial<{

  rows:number

}>

// "extends" file parser to process the contents of a file as workbook.
export const useXlsxParser = <T, S>() => {

  const asyncSupport = useContext(ParseAsyncContext)

  const parse = useFileParser('binary')


  return (file: Resource) => ({

    with: (bookparser: WorkbookParser<T, S>, ctx: ParseContext<S>) => parse(file).with(async data => {

      const { read } = asyncSupport.get().xlsx ??

        // import bundle
        await import('xlsx').then(({ utils, read }) => {

          asyncSupport.setQuietly(c => c.xlsx = { utils, read })

          return { read }

        })


      const {rows = 0 } = ctx as unknown as XlsXParseContext ?? {}

      const book = read(data, { type: "binary", cellDates: true, sheetRows: rows })

      return bookparser(book, ctx)

    })

  })
}

