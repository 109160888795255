import React from "react"


export const LayoutScrollContext = React.createContext<LayoutScrollSubscriptionApi>(undefined!)


export type LayoutScrollSubscriptionApi = {

    subscribe: (subscription: LayoutScrollSubscription) => void

    unsubscribe: (element: Element) => void

    disconnect: () => void

    root: HTMLElement
}


export type LayoutScrollSubscription = {

    setVisible: (_: boolean) => any

    element: Element
}



const scrollapi = (props: IntersectionObserverInit) => {

    const { root = document.documentElement, rootMargin = '10000px 350px' } = props

    const subscriptions = new WeakMap()

    const callback = (entries: IntersectionObserverEntry[]) => {


        entries.forEach(e => {
            
            const subscription = subscriptions.get(e.target)

            if (!subscription)
                return

            subscription.setVisible(e.isIntersecting)

        })

    }

    const options = { rootMargin, root } as IntersectionObserverInit

    const observer = new IntersectionObserver(callback, options)

    return {

        subscribe: (subscription: LayoutScrollSubscription) => {

            // store subscription to notify it later as the observer reports on its visibility status.
            subscriptions.set(subscription.element, subscription)

            // register element with observer.
            observer.observe(subscription.element)

        },


        unsubscribe: (element: Element) => {

            subscriptions.delete(element)
            observer.unobserve(element)
        }

        ,
        
        disconnect: () => observer.disconnect()

        ,

        root
    } as LayoutScrollSubscriptionApi
}


export const LayoutScrollProvider = (props: React.PropsWithChildren) => {

    const { children } = props

    const wrapperRef = React.useRef<HTMLDivElement>(null)

    const [ api, setApi ] = React.useState<LayoutScrollSubscriptionApi>(undefined!)

    React.useEffect(() => {

        const api = scrollapi({ root: wrapperRef.current! })
        
        setApi(api)

        return () => api.disconnect()

    },[])

    return <LayoutScrollContext.Provider value={ api }>
        <div ref={ wrapperRef } style={{ height: '100%', maxWidth: '100%' }}>
            { children }
        </div>
    </LayoutScrollContext.Provider>

}