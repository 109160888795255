
import { State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import React, { PropsWithChildren, createContext, useContext } from 'react'

// an interface for UI components to access preferences.
// needs an implementation to provide persistence.
export type UsernameResolver = {

  resolve: (_:string) => React.ReactNode

}

export type PreferenceUpdater<T = UsernameResolver> = (_: T) => void

// stores the implementation.
export const usernameResolverContext = createContext<State<UsernameResolver>>(undefined!)

// a no-op implementation (no preferences).
const initialResolver: UsernameResolver = { resolve: username => username }


// used to register an implementation of the interface.
export const useUsernameResolverPlugin = () => {

  const ctx = useContext(usernameResolverContext)

  return {

    register: (plugin: UsernameResolver) => ctx.reset(plugin)
  }

}

// provides r/w access to UI preferences, based on a some registered implementation.
export const useUsernameResolver = () => {


  const plugin = useContext(usernameResolverContext).get() as UsernameResolver

  return plugin.resolve

}


export const UsernameResolverProvider = (props: PropsWithChildren) => {

  const { children } = props

  return <StateProvider context={usernameResolverContext} initialState={initialResolver}>
    {children}
  </StateProvider>
}
