
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { Tenant } from './model';
import { TenantPreferencesModule } from './modules';


// cache 
export type TenantCache = {

    all: Tenant[]
    allRefs: Tenant[]
}

export const initialCacheState: TenantCache = {

    all: [],
    allRefs: []
}

export const TenantCacheContext = createContext<State<TenantCache>>(undefined!)


export type TenantRoutingState = {
p
    nextTenant: Tenant
    routeAfterNext: (added: Tenant) => any

}

export const TenantRoutingContext = createContext<State<Partial<TenantRoutingState>>>(undefined!)

export const initialRoutingState: Partial<TenantRoutingState> = {}



export type TenantPreferencesModuleState = {

    modules: TenantPreferencesModule[]

}
export const TenantPreferencesModuleContext = createContext<State<TenantPreferencesModuleState>>(undefined!)

export const initialPreferenceModuleState = {

    modules: [] as any[]
}

export const TenantProvider = (props: PropsWithChildren) => {

    return <StateProvider initialState={initialCacheState} context={TenantCacheContext}>
        <StateProvider initialState={initialRoutingState} context={TenantRoutingContext}>
            <StateProvider initialState={initialPreferenceModuleState} context={TenantPreferencesModuleContext}>
                {props.children}
            </StateProvider>
        </StateProvider>
    </StateProvider>
}



