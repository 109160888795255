import { useConfig } from 'apprise-frontend-core/config/api'
import { useT } from 'apprise-frontend-core/intl/language'
import { useAsyncTask } from 'apprise-ui/utils/asynctask'
import { ParserConfig } from './config'
import { useJsonParser, useXlsxParser } from './file'
import { ItemParser, ModelParser, ParseContext, ParseIssue, Resource, ResourceParser } from './model'
import { WorkbookParser } from './workbook'


type DefaultParserProps<T, S> = {

  modelparser: ModelParser<T, S>
  bookparser: WorkbookParser<T, S>

}

export const useDefaultParser = <T, S>(props: DefaultParserProps<T, S>) : ResourceParser<T, S>  => {

  const t = useT()
  const task = useAsyncTask()


  const { modelparser: parseModel, bookparser: parseBook } = props

  const { parse: cfg } = useConfig<ParserConfig>()

  const parseJson = useJsonParser<T,S>()
  const parseXlsx = useXlsxParser<T,S>()

  return task.make( async (resource: Resource, ctx: ParseContext<S>) => {

    const type = resource.file.type

    if (cfg?.xslxMimeTypes?.includes(type))
      return parseXlsx(resource).with(parseBook, ctx) 

    if (cfg?.jsonMimeTypes?.includes(type))
      return parseJson(resource).with(parseModel, ctx)

    throw Error(`unknown file type: ${type} `)


  }).with(config => config

    .show(resource => t('sub.resource_parse', { name: resource.name }))

  ).done()



}


export const useDefaultModelParser = <T, S>(parser: ItemParser<T, S>): ModelParser<T, S> => {

  const t = useT()

  return (rows: any[], ctx: ParseContext<S>) => {

    const issues: ParseIssue[] = []
    const data = rows.map(row => {

      try {

        return parser(row, ctx)

      }
      catch (e: any) {

        issues.push({ type: e.type ?? 'error', message: e?.message ?? t('parse.unspecified_error') });
        return undefined!
      }

    }).filter(p => !!p)

    return { data, issues }

  }
}