import { fallbackStateOver, State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { elementContainerRole, Optional } from 'apprise-frontend-core/utils/common'
import { createContext, PropsWithChildren, useContext, useEffect, useLayoutEffect, useRef } from 'react'



export type ScrollRestorationProps = {

    name?: string
    scrollable: Optional<Element>

}

export type ScrollRestorationState = Record<string, number>

export const ScrollRestorationContext = createContext<State<ScrollRestorationState>>(fallbackStateOver({}))

export const ScrollRestorationProvider = (props: PropsWithChildren) => {

    const { children } = props

    return <StateProvider initialState={{}} context={ScrollRestorationContext}>
        {children}
    </StateProvider>
}

ScrollRestorationProvider[elementContainerRole] = true

export const useScrollRestoration = (props: ScrollRestorationProps) => {

    // use of name is guard
    const { name, scrollable } = props

    const ctx = useContext(ScrollRestorationContext)

    const scrollableRef = useRef<Element>()

    // restore scroll on the element if we have to, and as soon as we have the element. 
    useEffect(() => {

        if (!name || !scrollable)
            return

        const storedTop = ctx.get()?.[name]
      
        if (storedTop !== undefined)
            setTimeout(() => scrollable.scrollTop = storedTop, 30);
        
        //eslint-disable-next-line
    }, [scrollable])

    // remembers latest scrollable node to use it on unmount.
    useEffect(() => {

        if (scrollable)
            scrollableRef.current = scrollable

        //eslint-disable-next-line
    }, [scrollable])


    // remember scroll position on unmount.
    useLayoutEffect(() => {

        return () => {

            if (scrollableRef.current && name)
                ctx.setQuietly(s => s[name] = (scrollableRef.current as Element).scrollTop)

        }

        // eslint-disable-next-line
    }, [])

}