
import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import React, { useEffect } from 'react'
import { RemoveItemIcon } from './icons'

// identifies the shared context of filters that can be rememebred or cleared together.
type FilterContext = string

// identifiers the state of a filter in a given context.
type FilterKey = string

// hollds the current state of filters in given contexts.
type FilterState = Record<FilterContext, Record<FilterKey, any>>

// the commons props of all filters that share their state in a context.
export type ContextualFilterProps = {

    contextKey?: FilterKey
    context: FilterContext
}

type FilterContextType = [FilterState, React.Dispatch<React.SetStateAction<FilterState>>]

const ReactFilterContext = React.createContext<FilterContextType>(undefined!)

const dependencyKey = "__dep"

// holds filter state and makes it availale to its descendants.
export const FilterProvider = (props: React.PropsWithChildren) => {

    const [filters, setFilters] = React.useState<FilterState>({})

    // groups state and state change function for sharing. 
    // but memoises it, so that consumers re-render only when the state change.  
    const sharedState: FilterContextType = React.useMemo(() => [filters, setFilters], [filters])

    return <ReactFilterContext.Provider value={sharedState}>
        {props.children}
    </ReactFilterContext.Provider>
}


// api to read and write in the state of a particular context.
export const useFilterState = <S=any> (context: FilterContext) => {

    const [filters, setFilters] = React.useContext(ReactFilterContext)

    const self = {

        set: (key: FilterKey) => (value: S) => {
            

            const addKey = (fs: FilterState) => ({ ...fs[context], [key]: value })
            const removeKey = (fs: FilterState) => Object.keys(fs[context]).reduce((acc, k) => key === k ? acc : { ...acc, [k]: fs[context][k] }, {})

            setFilters(fs => ({ ...fs, [context]: value ? addKey(fs) : removeKey(fs) }))

        }
        ,

        get: (key: FilterKey) => filters[context]?.[key] as S

        ,


        all: () => filters[context]

        ,

        isEmpty: () => Object.keys(filters[context] ?? {}).filter(k=>k!==dependencyKey).length === 0

        ,

        reset: () => setFilters(fs => Object.keys(fs).reduce((acc, grp) => context === grp ? acc : { ...acc, [grp]: fs[grp] }, {}))
    }




    return self
}


// reset all filters in a given context in sync with changes to some dependency.
export const useResetFilters = (context: FilterContext, dependency: any) => {

   const filters = useFilterState(context)

    useEffect(()=> {

        if (!dependency)
        return

        const previous = filters.get(dependencyKey)

        if (previous && previous!==dependency)
            filters.reset()
        
        filters.set(dependencyKey)(dependency);

    // eslint-disable-next-line
    },[dependency])
}


export const ClearFilters = (props: {
    
    context: string

}) => {

    const t = useT()

    // won't use prefix, but do need one alias to reset all related ones.
    const ctx = useFilterState(props.context)

    return <Button noReadonly style={{ visibility: ctx.isEmpty() ? 'hidden' : 'visible' }} type='ghost' onClick={ctx.reset} tip={t("ui.menu.clear_filters")}>
         <RemoveItemIcon />
    </Button>

}