import { useInclusionList } from 'apprise-frontend-core/config/api'
import { Multilang, useL, useMultilang } from 'apprise-frontend-core/intl/multilang'
import { OneOrMore, Optional, utils } from 'apprise-frontend-core/utils/common'
import { Lifecycle } from 'apprise-frontend-core/utils/lifecycle'
import { tagType } from 'apprise-frontend-tags/constants'
import { CustomPropertyRef } from 'apprise-frontend-tags/customprop/model'
import { CategoryReference, TagCategory } from '../category/model'



export type TagLifecycleState = 'active' | 'inactive'

export type Tag = {

    // unambiguously distinguishes this tag from other tags over time.
    id: string

    // constrains the domain objects that can be annotated with this tag.
    // cannot change over time.
    type: string

    // the category the tag may belong to and be constrained by (must have same target type).
    // cannot change over time.
    category?: CategoryReference

    // short and mnemonic, must be unique in the scope of the tag's type.
    name: Multilang

    // shorter form of the name, preferred in space-constrained contexts.
    shortname?: Multilang

    // free-form and instructional.
    description: Multilang

    // codifies the underlying concept from some codelist of relevance in the application domain.
    code?: string

    // tracks event timestamps, event agency, and current/previous state.
    lifecycle: Lifecycle<TagLifecycleState>

    // flags the tag as protected from unintended change to its properties.
    // can change at any time.
    guarded?: boolean

    // flags the tag is required by the system, so it can only be partially changed and it can never be removed.
    // predefined tags cann't change state or dependencies. they may change other fields if/when unguarded.
    // cannot change over time.
    predefined?: boolean,


    // extension point for additional property for the tag. opaque to the backend.
    properties: TagProperties


}


export type TagProperties = Partial<{

    // a boolean combination of tags in same/other categories, intended as dependencies for the tag.
    // if present, must be true or the tag won't be available for use in forms defined over objects of the target type.
    includeCondition: TagExpression

    // the current value of all custom properties (if any) specified in the category of the tag (if any). 
    custom: CustomProperties

    // a single, unconstrained tag property for custom use.
    // this is unconstrained and unstructured and should be superseded by explicit custom properties in most use cases.
    value: any

    //Sort order
    sort: number

}>

export type CustomProperties = Record<CustomPropertyRef, any>

export type TagReference = string

export const noRef = 'noneOf'

export type TagExpression = {

    terms: ExpressionTerm[]
}

export type TagOperator = 'anyOf' | 'allOf' | 'noneOf'

export type ExpressionTerm = {

    category: string,
    tags: string[],
    op?: TagOperator
}


export type Tagged = {

    tags: TagReference[]
}


export const unknownTag = 'unknown'

export const useTagModel = () => {

    const l = useL()
    const ml = useMultilang()
    const { isIncluded } = useInclusionList(tagType)

    const self = {


        // returns the text fingerprint of one or more tags.
        textOf: (tags:OneOrMore<Optional<Tag>>) => utils().purge(utils().arrayOf(tags)).flatMap(t=>[l(t.name),t.code])

        ,

        newTag: (type: string='system'): Tag => ({

            id: undefined!,
            type,
            lifecycle: { state: isIncluded('active') ? 'inactive' : 'active' },
            name: {},
            description: {},
            properties: {}

        })

        ,

        newTagInCategory: (category: TagCategory): Tag => ({

            id: undefined!,
            category: category.id,
            type: category.type,
            lifecycle: { state: 'inactive' },
            name: {},
            description: {},
            properties: {}
        })

        ,

        // returns a tag where none can't be found: we have no id or the id cannot be resolved.
        noTag: (name?: Optional<TagReference>): Tag => ({

            id: unknownTag,
            type: unknownTag,
            lifecycle: { state: 'active' },
            name: { en: name || undefined },
            description: undefined!,
            properties: {}
        })

        ,

        comparator: (o1: Tag, o2: Tag) => {

            if (o1.properties.sort || o2.properties.sort) {
                const o1SortValue = o1.properties.sort ?? Number.MAX_VALUE
                const o2SortValue = o2.properties.sort ?? Number.MAX_VALUE

                if (o1SortValue === o2SortValue) return ml.comparator(o1.name, o2.name)

                return o1SortValue - o2SortValue

            }

            return ml.comparator(o1.name, o2.name)

        }

        ,

        // compares tags but puts one particular tag before all others (used for context)
        biasedComparator: (bias: Tag) => (o1: Tag, o2: Tag) => l(bias.name) === l(o1.name) ? l(bias.name) === l(o2.name) ? 0 : -1 : l(bias.name) === l(o2.name) ? 1 : self.comparator(o1, o2)

        ,

        // compares tags but puts one particular tag before all others (used for context)
        codeComparator: utils().compareStringsOf((o:Tag) => o.code)

        ,



        given: (tags: Tag[]) => {

            const api = {


                allWithCategory: (catRef: string) => tags.filter(t => t.category === catRef),
                allWithType: (type: string) => tags.filter(t => t.type === type),
                matches: (e: TagExpression | undefined) => !e || self.expression(e).matches(tags),
                filterMatching: (othertags: Tag[] = tags) => othertags.filter(t => !t.properties.includeCondition || api.matches(t.properties.includeCondition))

            }


            return api
        }

        ,

        expression: (expr: TagExpression) => ({

            matches: (tags: Tag[]) => {

                const evaluateTerm = (term: ExpressionTerm) => {

                    const targets = self.given(tags).allWithCategory(term.category).map(t => t.id)

                    if ((term.tags?.length ?? 0) === 0)
                        return true

                    const matched = targets.filter(t => term.tags?.includes(t))

                    let match: boolean;

                    switch (term.op) {

                        case 'anyOf': match = matched.length > 0; break
                        case 'allOf': match = matched.length === term.tags.length; break
                        default: match = matched.length === 0; break

                    }

                    //console.log("matching",t,"with term",term,"results in",match)

                    return match
                }


                return !expr.terms.map(evaluateTerm).some(r => r === false)

            }
        })

        ,

        clone: (tag: Tag): Tag => ({ ...utils().deepclone(tag), id: undefined!, lifecycle: { state: 'inactive' } })

        ,

        customProperties: <T extends CustomProperties>(tag: Tag) => tag.properties.custom as T

    }


    return self
}