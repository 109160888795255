import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { Button } from 'apprise-ui/button/button'
import { Component } from 'apprise-ui/component/component'
import { classname, Disabled, Styled } from 'apprise-ui/component/model'
import { Draggable } from 'apprise-ui/dnd/draggable'
import { Droppable } from 'apprise-ui/dnd/droppable'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { Predicate } from 'apprise-ui/field/validation'
import { Linked } from 'apprise-ui/link/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { Tipped } from 'apprise-ui/tooltip/model'
import { AddIcon, DotIcon, RemoveItemIcon } from 'apprise-ui/utils/icons'
import { Placeholder } from 'apprise-ui/utils/placeholder'
import React, { Fragment, useRef } from 'react'
import './styles.scss'


export type GroupBoxProps<T extends any = any> = Fielded<T[]> & ChangeTracked<T[]> & Readonly & Partial<{

    name: string

    render: (_: T, index: number) => JSX.Element

    // return a new item to add.
    newItem: () => T

    // handles new item addition directly, as an alternative to newItem().
    onAdd: () => any
    disabledAdd: boolean

    noAdd: true,
    addIf: boolean,
    addMode: 'header' | 'footer'

    noRemove: true
    removeIf: (_: T, index: number) => boolean
    disabledRemoveIf: (_: T) => boolean

    disableIf: (_: T, index: number) => boolean

    validate: (_: T | undefined, index: number) => ReturnType<Predicate> | ReturnType<Predicate>[]

    noReorder: boolean

    children: T[]

}>

export const GroupBox = <T extends any = any>(clientprops: GroupBoxProps<T>) => {

    const t = useT()

    const props = useFieldProps(clientprops)

    const {

        name,

        className,

        innerClassName, innerStyle,

        render = (t: T) => <Fragment>{t as any}</Fragment>,
        newItem,
        onAdd,

        noAdd,
        addIf: clientAddIf = true,
        addMode = 'header',

        noRemove,
        removeIf: clientRemoveIf = () => true,

        disableIf,

        validate,

        noReorder,

        ...rest

    } = props


    const currentValue = props.children ?? props.defaultValue ?? []

    const { pastMode, pastValue } = useChangeHelper(props)

    const latestValue = pastMode ? pastValue : currentValue

    useReadonlyHelper(props)

    useResetHelper(props)

    const { readonly, noReadonly } = rest

    const id = useRef(`${new Date().getTime()}`)

    const addIf = !noAdd && clientAddIf
    const removeIf = (_: T, index: number) => !noRemove && clientRemoveIf(_, index)

    const add = () => onAdd ? onAdd() : newItem ? props.onChange?.([...latestValue, newItem?.() as T]) : () => { } // no-op if have nothing to go.
    const remove = (t: T) => props.onChange?.(latestValue.filter(tt => tt !== t))
    const move = (from: T, to: T) => {

        if (from !== to)
            props.onChange?.(latestValue.flatMap(c => c === from ? [] : c === to ? [from, to] : [c]))

    }


    const addBtnHeader =  <Button className='group-add-btn' type='ghost' size='small' readonly={readonly} noReadonly={noReadonly} disabled={props.disabled || props.disabledAdd} icon={<AddIcon size={11} />} onClick={add}>
        <span className="add-btn-text">{t('ui.group_add', { singular: name })}</span>
    </Button>

    const removeBtn = (tt:T) =><Button className='group-remove-btn' readonly={readonly} noReadonly={noReadonly} disabled={props.disabled || props.disabledRemoveIf?.(tt)} type="ghost" tip={t("ui.group_remove", { singular: name })} onClick={() => remove(tt)}>
        <RemoveItemIcon />
    </Button>

    const addBtnFooter = <Button readonly={readonly} noReadonly={noReadonly} disabled={props.disabled} type='ghost' icon={<AddIcon />} onClick={add}>{t('ui.group_add', { singular: name })}</Button>

    const iteminfo = latestValue.flatMap((t:T, index: number) => {

        const predicates = utils().arrayOf(validate?.(t,index) ??[])

         return predicates.filter(i=>i).map(info => ({...info,location:index}))  /// keep only issues and add location marker.

    }).find(info => info.status !== 'success')


    const classes = classname('groupbox', `addmode-${addMode}`, className)

   
    return <Field name='groupbox' {...rest} {...iteminfo} className={classes} >

        <div style={{ width: '100%', ...innerStyle }} className={innerClassName} >

            {addIf && addMode==='header' && latestValue.length > 0 && <div className='group-topbar'>{addBtnHeader}</div>}


            {latestValue.map((child:T, key:number) => {

                const disabled = props.disabled || disableIf?.(child, key)

                return <div key={key} className={"group-row"} >
                    <div className={classname("group-item", iteminfo?.location===key && `group-item-${iteminfo?.status}`)}>

                        {noReorder ?

                            render(child, key)

                            :

                            <Droppable className='groupbox-droppable' readonly={readonly} noReadonly={noReadonly} disabled={disabled} accept={[id.current]} path={`${key}`} onDrop={item => move(latestValue[item], latestValue[key])}>
                                <Draggable readonly={readonly} noReadonly={noReadonly} disabled={disabled} key={key} type={id.current} item={key}>
                                    {render(child, key)}
                                </Draggable>
                            </Droppable>
                        }
                    </div>
                    {removeIf(child, key) && 
                    <div className="group-bar">
                        {removeBtn(child)}
                    </div>}

                </div>
            })}

            <Placeholder visible={addIf && (latestValue.length === 0 || addMode==='footer')}>{addBtnFooter}</Placeholder>

        </div>

    </Field>
}


export type CardProps = Styled & Tipped & Linked & Disabled & React.PropsWithChildren<{

    dot?: boolean
}>

GroupBox.Card = (props: CardProps) => {

    const { children, dot, disabled, ...rest } = props

    return <Component name="groupbox-card" disabled={disabled} {...rest}>
        {children}
        {dot && <DotIcon className='card-dot' />}
    </Component>



}