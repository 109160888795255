import { useT } from 'apprise-frontend-core/intl/language';
import { utils } from 'apprise-frontend-core/utils/common';
import partition from 'lodash/partition';
import { useContext } from 'react';
import { CellAddress, WorkBook, WorkSheet } from 'xlsx';
import { defaultParserConfig, ParseKey, ParserConfig } from './config';
import { ParseAsyncContext } from './context';
import { emptyResourceParseOutcome, ModelParser, ParseContext, ParseIssue, ResourceParseOutcome } from './model';

// excel workbook parsing contract: take a workbook and an optional parsing context and produce records for a submission.
export type WorkbookParser<T, S> = (book: WorkBook, ctx: ParseContext<S>) => ResourceParseOutcome<T>



type XlsxConfig = ParserConfig['parse'] & {

    match: (value: string, config: string) => boolean
    matchStart: (value: string, config: string) => boolean

}



// default 2-phase workbook parser: uses a default, config-driven strategy to extrac data from a workbook, then passes it to a model parser.
// a workbook parser based on a default strategy.
export const useDefaultBookParser = <T, S>(modelparser: ModelParser<T, S>, configProducer: () => ParserConfig, ...configTypes: string[]): WorkbookParser<T, S> => {

    const t = useT()

    const asyncSupport = useContext(ParseAsyncContext)

    const parseConfig = useXlsxParseConfig(configProducer())

    // extracts data from a single sheet, driven by configuration.
    const parseSheet = (sheet: WorkSheet, name: string, index: number, config: XlsxConfig, ctx: ParseContext<S>): ResourceParseOutcome<any> => {

        if (!sheet['!ref'])
            return emptyResourceParseOutcome()

        const { xlsx } = asyncSupport.get()

        const { encode_cell, encode_range, sheet_to_json } = xlsx.utils

        // entire sheet range.
        const range = xlsx.utils.decode_range(sheet['!ref'])

        const {

            headerRowRadius = defaultParserConfig.headerRowRadius!,
            headerColRadius = defaultParserConfig.headerColRadius!,
            headerCornerstoneRowOffset = defaultParserConfig.headerCornerstoneRowOffset!,
            headerCornerstoneColOffset = defaultParserConfig.headerCornerstoneColOffset!,

            rowRadius, colRadius

        } = config



        // searches for the cornerstone of a header, its top-left cell, within a configured window.
        // the cells is recognised by the content.
        var cornerstone: CellAddress | undefined = undefined

        out: for (var i = range.s.r; i < Math.min(range.e.r, headerRowRadius); ++i)
            for (var j = range.s.c; j < Math.min(range.e.c, headerColRadius); ++j) {

                const value = sheet[encode_cell({ r: i, c: j })]?.v

                if (utils().arrayOf(config.headerCornerstone).some(c => config.match(`${value}`, c) )) {
                    cornerstone = { r: i, c: j }
                    break out
                }
            }


        const noOutcome = emptyResourceParseOutcome()

        const raiseEmptySheetIssueIfRequired = (type?: | ParseIssue['type']) => {
            
            const raiseAs = (type: ParseIssue['type']) =>  ( { ...noOutcome, issues: [{ type, message: t(`parse.empty_sheet`, { sheet: name }) }] })
           
            return config.includeSheet?.find(s => config.matchStart(name, s)) || config.includeSheetIndex?.find(i => index===i)  ?

                raiseAs('error')

                :
                
                type ? raiseAs(type) : noOutcome
            
        }
      

        // no header could be found => not a data carrying sheet.
        // emits a warning if this sheet was explicitly included in configuration;
        if (!cornerstone)
            return raiseEmptySheetIssueIfRequired()
            

        // the range starts from the cornerstone (optionally with a row/col offset)
        // and stretches to the end of the sheet.
        const datarange = {

            s: { r: cornerstone.r, c: headerCornerstoneColOffset + cornerstone.c },
            e: { r: rowRadius ?? range.e.r, c: colRadius ?? range.e.c }

        }

        // extracts the data in the range as json objects where keys are 1-to-1 with header cells.
        // empty cells are also included with a null value.
        const extracted = (sheet_to_json(sheet, { range: encode_range(datarange), defval: null, UTC: true }) as any[]).slice(headerCornerstoneRowOffset)

        if (extracted.length === 0)
            return raiseEmptySheetIssueIfRequired(typeof ctx.emptySheetIssue === 'string' ? ctx.emptySheetIssue : undefined)
        

        // takes the keys from the first row (all rows have the same key for 'defval' above).
        const rowKeys = Object.keys(extracted[0] ?? {})

        // uses configuration to map extracted keys onto canonical names.
        // effectively tries to extract the canonical json serialisation of a record patch. 
        const mappedEntries = Object.entries(config.keys).map(([key, canonical]) => {

            const colAliases = canonical.colAliases === undefined ? [] : typeof canonical.colAliases === 'string' ? [canonical.colAliases] : canonical.colAliases

            const keys = [key, ...colAliases ?? []]

            // keys are normalised and matched with some prefix in configuration.
            return [rowKeys.find(k => keys.some(kk => canonical.exact ? config.match(k,kk) : config.matchStart(k, kk))), canonical] as [string, ParseKey]

        })

        // separates config keys that do not have values in the data.
        // the latter are columns that were missing altogether from file.
        const [matched, unmatched] = partition(mappedEntries, ([key]) => key)

        // generates parsing issues as warnings for missing columns.
        const missing: ParseIssue[] = unmatched.map((([, canonical]) =>

            ({ type: 'warning', message: t(`parse.no_column_warning`, { col: t(canonical.tkey ?? canonical.key), sheet: name }) })))

        const internFor = (canonical: ParseKey, original: string) => {

            let cast = original!==undefined && original!==null && (typeof original !== 'string')?  original+"" : original

            const trimmed = cast?.trim()

            return trimmed && trimmed in (canonical.aliases ?? {}) ? canonical.aliases?.[trimmed] : trimmed

        }

        const rowFilter = Object.values(matched).filter(([, canonical]) => canonical.filter).reduce((acc, [original, canonical]) => row => acc(row) && !!canonical.filter?.(row[original]), (_: any) => true)

        // transforms the extracted rows to canonical json patches along the matched keys.
        const rows = extracted.filter(rowFilter).map(row => matched.reduce((acc, [original, canonical]) => ({ ...acc, [canonical.key]: internFor(canonical, row[original]) }), {}))

        return { data: rows, issues: missing }

    }

    return (book: WorkBook, ctx: ParseContext<S>) => {

        const { matchStart, includeSheet, includeSheetIndex, excludeSheet, excludeSheetIndex } = parseConfig(configTypes)

        const sheetAllowed = (name: string, index: number) => 
        
            includeSheet ?  includeSheet.some(s => matchStart(name,s)) :
            includeSheetIndex ? includeSheetIndex.includes(index) :
            excludeSheet ? !excludeSheet.some(s => matchStart(name,s)) :
            excludeSheetIndex ? !excludeSheetIndex.includes(index) : true

        const outcome = emptyResourceParseOutcome<T>()

        // parses each sheet and aggreagtes the results.
        Object.values(book.SheetNames)

            .filter(sheetAllowed)

            .map(sheet =>

               parseSheet(book.Sheets[sheet], sheet, Object.keys(book.Sheets).indexOf(sheet), parseConfig(configTypes, sheet), ctx)

            )
            .reduce((acc, next) => {

                const parseOutcome = modelparser(next.data, ctx)

                acc.data.push(...parseOutcome.data)
                acc.issues.push(...next.issues, ...parseOutcome.issues)

                return acc

            }, outcome)

    
        return outcome

    }
}

export const useXlsxParseConfig = (parseConfig: ParserConfig) => {

    const { parse: config } = parseConfig

    const normalise = (s: string) => s.trim().toLowerCase().replace(/\s+/g, '_')

    return (configTypes: string[] = [], sheet?: string): XlsxConfig => ({

        ...configTypes.reduce((acc, type) =>

            utils().merge(acc, type ? config?.type?.[type] ?? {} : {}, type && sheet ? config?.type?.[type]?.sheet?.[sheet] ?? {} : {})

            , config)

        ,

        match: (value: string, config: string) => normalise(value) === normalise(config)

        ,

        matchStart: (value: string, config: string) => normalise(value).startsWith(normalise(config))

    })

}