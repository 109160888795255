
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { User } from './model';
import { UserPermissionModule, UserPreferencesModule } from './modules';


export const UserCacheContext = createContext<State<UserCache>>(undefined!)


// cache

export type UserCache = {

    all: User[]
    admins: User[]
}

const initialUserCache: UserCache = {

    all: [],
    admins:[]
}


// routing


export type UserRoutingState = {

    nextUser: User

}

export const UserRoutingContext = createContext<State<Partial<UserRoutingState>>>(undefined!)

const initialRoutingState: Partial<UserRoutingState> = {}


/* Permission Modules */

export type UserPermissionModulesState = {

    modules: UserPermissionModule<any>[]

}

const initialModuleState: UserPermissionModulesState = {

    modules: []

}

export const UserPermissionModuleContext = createContext<State<UserPermissionModulesState>>(undefined!)


/* Preferences Modules */

export type UserPreferencesModuleState = {

    modules: UserPreferencesModule[]

}
const initialPreferencesModuleState: UserPreferencesModuleState = {

    modules: []

}

export const UserPreferencesModuleContext = createContext<State<UserPreferencesModuleState>>(undefined!)




export const UserProvider = (props: PropsWithChildren) => {

    return <StateProvider initialState={initialModuleState} context={UserPermissionModuleContext}>
         <StateProvider initialState={initialPreferencesModuleState} context={UserPreferencesModuleContext}>
        <StateProvider initialState={initialUserCache} context={UserCacheContext}>
            <StateProvider initialState={initialRoutingState} context={UserRoutingContext}>
                {props.children}
                </StateProvider>
            </StateProvider>
        </StateProvider>
    </StateProvider>
}

