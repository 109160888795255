
import { useT } from 'apprise-frontend-core/intl/language'
import { State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { useStable } from 'apprise-frontend-core/utils/function'
import { Button, ButtonProps } from 'apprise-ui/button/button'
import { createContext, PropsWithChildren, useContext } from 'react'
import { useAsyncTask } from './utils/asynctask'
import { SaveIcon } from './utils/icons'

// an interface for UI components to access preferences.
// needs an implementation to provide persistence.
export type Preferences<T extends Record<string,any> = Record<string,any>>  = {

  isEnabled: () => boolean 

  get: () =>T

  set: (_: PreferenceUpdater<T>) => Promise<void>

}

export type PreferenceUpdater<T = Preferences> = (_: T) => void

// stores the implementation.
export const preferencesContext = createContext<State<Preferences>>(undefined!)

// a no-op implementation (no preferences).
const initialPreferences: Preferences = { isEnabled: () => true, get: () => ({}), set: async () => { } }


// used to register an implementation of the interface.
export const usePreferencesPlugin = () => {

  const ctx = useContext(preferencesContext)

  return {

    register: (plugin: Preferences) => ctx.reset(plugin)
  }

}

// provides r/w access to UI preferences, based on a some registered implementation.
export const useUIPreferences = <T extends  Record<string,any>>() => {

  const t = useT()
  const task = useAsyncTask()

  const plugin = useContext(preferencesContext).get() as Preferences<T>

  const set = task.make(async (updater:PreferenceUpdater<T>) => plugin.set(updater) ).with($=>$.wait(100)).done()

  // saves preferences using a well-typed updater. 
  const SavePreferencesButton = useStable((props: ButtonProps & { updater: PreferenceUpdater<T> }) => {

    const { updater, ...rest } = props

    return <Button tipDelay={1} tip={t('user.save_preferences_tip')} type='link' iconPlacement='left' icon={<SaveIcon />} onClick={() => set(updater)} {...rest}>
      {t('user.save_preferences')}
    </Button>

  })


  const self = { ...plugin, set, SavePreferencesButton }

  return self

}


export const PreferencesProvider = (props: PropsWithChildren) => {

  const { children } = props

  return <StateProvider context={preferencesContext} initialState={initialPreferences}>
    {children}
  </StateProvider>
}
