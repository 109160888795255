import React from 'react'
import { utils } from './common'


// returns the value of a variable in the previous render, if any.

export const usePrevious = <T extends any> (current:T) => {

    // we track changes with useState so that updates are visible in future renders only.
    // if we used useRef, other effect might not see the value of previous in _this_ render.
    // of course this causes one extra render.
    const [previous, previousSet] = React.useState<T>(undefined!)

    // saves the value in this render and rerenders.
    React.useEffect(() => {

        if (!utils().deepequals(current,previous))
            previousSet(current)

        // eslint-disable-next-line
    }, [current])


    return previous
    
}


// returns a stable proxy of the input object.
// the proxy will reflect the latest changes of the input without changing its identity.
export const useStableObject = <T extends object> (o:T) : T => {

    const ref = React.useRef<T>();
    
    ref.current = o;

    return React.useMemo( () => new Proxy(o, {
        
        get: (_,name:string) => ref.current?.[name]

    // eslint-disable-next-line
    } as unknown as T), [])

}