import { utils } from 'apprise-frontend-core/utils/common'
import { Component, useComponentProps } from 'apprise-ui/component/component'
import { classname, Disabled, Sized, Styled, StyledContainer } from 'apprise-ui/component/model'
import { Tipped } from 'apprise-ui/tooltip/model'
import React, { FC, PropsWithChildren } from 'react'
import { IconBaseProps } from 'react-icons'
import './explainer.scss'


export type ExplainerProps = React.PropsWithChildren<Styled & StyledContainer & Disabled &  Tipped & Sized> & Partial<{

  title: React.ReactNode
  icon: JSX.Element | FC<IconBaseProps>
  framed: boolean
  iconFill: boolean
  action: JSX.Element

}>

export const Explainer = (props: ExplainerProps) => {

  const { className, innerClassName, style, innerStyle, title, size, icon: Icon, iconFill, framed, action, ...rest } = useComponentProps(props)

  return <Component name='explainer' className={classname(className, framed && 'explainer-framed')} style={{ fontSize: size, ...style }} {...rest}>

    {title && <div className='explainer-title'>{title}</div>}

    {Icon && (typeof Icon === 'function' ? <Icon className='explainer-icon' /> : React.cloneElement(Icon, { className: classname(Icon.props.className, classname( 'explainer-icon', iconFill && 'icon-fill'  )) }))}

    <div className={classname(`explainer-body`, innerClassName)} style={innerStyle} >
      {props.children}
    </div>

    {action && React.cloneElement(action, { disabled: action.props.disabled || rest.disabled, className: classname('explainer-action',action.props.className) })}

  </Component>

}



export type ExplainerPanelProps = Styled & StyledContainer & PropsWithChildren



export const ExplainerPanel = (props: ExplainerPanelProps) => {

  const { style = {}, innerStyle = {}, className, innerClassName, children } = props

  const explainer = utils().elementsIn(children).find(utils().isElementOf(Explainer))
  const others = utils().elementsIn(children).filter(e => explainer !== e)

  return <div style={style} className={classname('apprise-explainer-panel', className)}>

    {explainer}

    <div style={innerStyle} className={classname('panel-body', innerClassName)}>
      {others}
    </div>


  </div>

}


