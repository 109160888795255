
import 'antd/dist/antd.css'
import { ComponentBridge } from 'apprise-frontend-core/utils/bridge'
import { LazyBusyGuard } from 'apprise-frontend-core/utils/busyguard'
import { useToolBridge } from 'apprise-frontend-core/utils/toolbridge'
import * as React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { IconContext } from 'react-icons/lib'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { bridge } from './bridge/bridge'
import { PreferencesProvider } from './preferences'
import { UsernameResolverProvider } from './usernameresolver'


export type Props = React.PropsWithChildren

export const UserInterface = (props: Partial<Props>) => {

    const { children } = props

    const { baseUrl } = useToolBridge()

    return (
        <ComponentBridge bridge={bridge}>
            <BrowserRouter basename={baseUrl}>
                <DndProvider backend={HTML5Backend}>
                    <ToastContainer />
                    <IconContext.Provider value={{ className: "apprise-icon" }}>
                        <LazyBusyGuard>
                            <PreferencesProvider>
                                <UsernameResolverProvider>
                                    {children}
                                </UsernameResolverProvider>
                            </PreferencesProvider>
                        </LazyBusyGuard>
                    </IconContext.Provider>
                </DndProvider>
            </BrowserRouter>
        </ComponentBridge>

    )
}

